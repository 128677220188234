import {
  AccountMenuCpt, AccountDetailCpt,
  AccountSubscriptionsCpt, AccountNotificationsSettingsCpt,
  CompanyAccountDetailCpt,
  UserActivityView,
  AccountUsersCpt,
  AccountForgottenProjectsCpt,
} from "./components";
import { AlertsView, AlertView } from "../alerts";
import { TagListCpt } from "../tags";

const rootPath: string = "/settings";

export const accountRoutes = [
  {
    path: `${rootPath}/account`,
    name: "account-settings",
    components: {
      leftmenu: AccountMenuCpt,
      content: AccountDetailCpt,
    },
    props: {
      leftmenu: () => ({ title: "Your account" }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/forgotten-projects`,
    name: "forgotten-projects-settings",
    components: {
      leftmenu: AccountMenuCpt,
      content: AccountForgottenProjectsCpt,
    },
    props: {
      leftmenu: () => ({ title: "Your account" }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/account/:accountId`,
    name: "accountView",
    components: {
      leftmenu: AccountMenuCpt,
      content: AccountDetailCpt,
    },
    props: {
      leftmenu: () => ({ title: "Your account" }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/company`,
    name: "company-settings",
    components: {
      leftmenu: AccountMenuCpt,
      content: CompanyAccountDetailCpt,
    },
    props: {
      leftmenu: () => ({ title: "Your account" }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/subscriptions`,
    name: "subscription-settings",
    components: {
      leftmenu: AccountMenuCpt,
      content: AccountSubscriptionsCpt,
    },
    props: {
      leftmenu: () => ({ title: "Your account" }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/notifications`,
    name: "notification-settings",
    components: {
      leftmenu: AccountMenuCpt,
      content: AccountNotificationsSettingsCpt,
    },
    props: {
      leftmenu: () => ({ title: "Your account" }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/tags`,
    name: "accounttags",
    components: {
      leftmenu: AccountMenuCpt,
      content: TagListCpt,
    },
    props: {
      leftmenu: () => ({ title: "Your account" }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/account-users`,
    name: "account-users",
    display: "Account Users",
    components: {
      leftmenu: AccountMenuCpt,
      content: AccountUsersCpt,
    },
    props: {
      leftmenu: () => ({ title: "Your account" }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `${rootPath}/activity/:userProfileId`,
    name: "user-activity",
    components: {
      leftmenu: AccountMenuCpt,
      content: UserActivityView,
    },
    props: {
      leftmenu: () => ({ title: "Your account" }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `/alerts`,
    name: "alerts",
    components: {
      leftmenu: AccountMenuCpt,
      content: AlertsView,
    },
    props: {
      leftmenu: () => ({ title: "Your account" }),
    },
    meta: { layout: "leftbar" },
  },
  {
    path: `/alerts/:alertId`,
    name: "alert",
    components: {
      leftmenu: AccountMenuCpt,
      content: AlertView,
    },
    props: {
      leftmenu: () => ({ title: "Your account" }),
    },
    meta: { layout: "leftbar" },
  },

];
