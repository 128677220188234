














































import { Component, Vue, Prop } from "vue-property-decorator";

import { SubscriptionModel, SubscriptionService } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class AccountSubscriptionTableCpt extends AppVue {
  @Prop({ default: () => [] }) model: SubscriptionModel[];
  async downloadInvoice(subId: number, invoiceId: number) {
    try {
      const result = await SubscriptionService.downloadInvoice(subId);
      if (!result) {
        throw new Error("File not found");
      }
      const filename = `CIS${invoiceId}.pdf`;
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      this.$notify({
        title: "Download completed!",
        type: "success",
        message: `${filename} downloaded successfully`,
      });
    } catch (err) {
      this.$notify({
        title: "Download failed!",
        type: "error",
        message: `The file doesn't exist`,
      });
    }
  }

  async created() {
    this.updateTitle("Subscriptions");
  }

  async mounted() {}
}
