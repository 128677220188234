













































import { Component } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { UserForgetProjectModel, UserForgetProjectService } from "@/core/services";

@Component({
  components: {},
})
export default class AccountForgottenProjectsCpt extends AppVue {
  loading: boolean = false;
  model: UserForgetProjectModel[] = [];

  get isLoading() {
    return this.loading;
  }

  async rememberProject(projectId: number) {
    this.loading = true;
    await UserForgetProjectService.DeleteUserForgetProject(projectId);
    this.notify("Project remembered again", "This project will appear in search results and alerts again.", "success");
    await this.loadData();
  }

  async created() {
    await this.loadData();
  }

  async mounted() {}

  async loadData() {
    this.loading = true;
    this.model = await UserForgetProjectService.getAllForgottenProjectsForUser();
    this.loading = false;
  }
}
