





















import { Component, Prop } from "vue-property-decorator";

import { AccountAdminService } from "@/core/services";
import { UserAnalyticsStatsModel, EditProfileModel, UserSessionModel } from "@/core/models";
import AppVue from "@/AppVue.vue";
import moment from "moment";

import ActivityTabPanelCpt from "../../administration/components/tabs/ActivityTabPanelCpt.vue";

@Component({
  components: {
    ActivityTabPanelCpt: () => Promise.resolve(ActivityTabPanelCpt),
  },
})
export default class UserActivityView extends AppVue {
  profile = new EditProfileModel();
  loading: boolean = false;
  session: UserSessionModel = new UserSessionModel();
  userStats = new UserAnalyticsStatsModel();
  userTrackedTotals: any[] = [];
  userSavedSearchesTotals: any[] = [];
  selectedYear = moment().year();
  selectedMonth = moment().month();
  dailyStat = false;
  selectedChart = "bar";
  years = [2019, 2020, 2021];

  get name() {
    return this.profile.name + " " + this.profile.lastname;
  }

  async refresh() {
    this.loading = true;
    const profileId = parseInt(this.$route.params.userProfileId, 10);
    this.profile = await AccountAdminService.getProfileById(profileId);
    if (this.profile.userId) {
      this.session = await AccountAdminService.getUserSession(this.profile.userId);
    }
    this.loading = false;
  }

  async loadData(model: any) {
    this.selectedMonth = model.selectedMonth;
    this.selectedYear = model.selectedYear;
    this.dailyStat = model.dailyStat;
    this.userStats = await AccountAdminService.getStats(
      this.profile.userId,
      this.dailyStat,
      this.selectedMonth,
      this.selectedYear,
    );
    this.userTrackedTotals = await AccountAdminService.getUserTrackedTotals(this.profile.userId);
    this.userSavedSearchesTotals = await AccountAdminService.getUserSavedSearches(this.profile.userId);
  }

  async created() {
    await this.refresh();
    await this.loadData({
      selectedYear: this.selectedYear,
      selectedMonth: this.selectedMonth,
      selectedChart: this.selectedChart,
      dailyStat: this.dailyStat,
    });
  }

  async mounted() {
    super.updateTitle("View user activity");
    const end = moment().year();
    const start = 2019;
    this.years = [...Array(end - start + 1).keys()].map((x) => start + x);
  }
}
