













































































import { Component, Prop } from "vue-property-decorator";
import { AccountService, ProfileModel } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { PagedCollection } from "@/core/models";

@Component({
  components: {},
})
export default class AccountUsersCpt extends AppVue {
  @Prop({ default: "active" }) filter: string;
  profiles: ProfileModel[] = [];
  loading: boolean = false;
  initialized: boolean = false;

  get isLoading() {
    return this.loading;
  }

  sinceLastLogin(incomingDate: Date) {
    if (incomingDate === null) {
      return 0;
    }

    const date = new Date(incomingDate);
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const oneMonthAgo = new Date();
    oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);

    if (date > oneWeekAgo) {
      return 1;
    }
    if (date > oneMonthAgo) {
      return 2;
    }
    return 3;
  }

  async created() {
    if (!this.initialized) {
      await this.init();
    }
  }

  async init() {
    this.loading = true;
    this.profiles = await AccountService.getCompanyAccounts();
    this.loading = false;
    this.initialized = true;
  }

  async mounted() {
    super.updateTitle("Account users");
  }
}
