































































import { Component, Vue, Prop } from "vue-property-decorator";

import { AccountService } from "@/core/services";
import { RadioButtonTristateCpt } from "@/core/components";

import AppVue from "@/AppVue.vue";
import { AlertSetupModel } from "@/core/models";
enum AlertFrequency {
  Never = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
}

@Component({
  components: {
    RadioButtonTristateCpt: () => Promise.resolve(RadioButtonTristateCpt),
  },
})
export default class AccountNotificationsSettingsCpt extends AppVue {
  @Prop({ default: () => ["Off", "Daily", "Weekly"] }) values: string[];
  loading: boolean = false;
  model: AlertSetupModel = new AlertSetupModel();
  schedules = ["Never", "Daily", "Weekly", "Monthly"];
  get isLoading() {
    return this.loading;
  }

  async created() {
    this.updateTitle("Alert Settings");
  }

  async mounted() {
    this.loading = true;

    this.model = await AccountService.getUserAlertSettings();
    this.loading = false;
  }

  async setIsActive(value: string) {
    this.loading = true;
    this.model.isActive = value === "On";
    /// save
    this.model = await AccountService.setUserAlertSettings(this.model);
    this.loading = false;
  }

  async setIsAlertSentViaSms(value: string) {
    this.loading = true;

    this.model.isAlertSentViaSms = value === "On";
    /// save
    this.model = await AccountService.setUserAlertSettings(this.model);
    this.loading = false;
  }

  async setIsAlertSentViaemail(value: string) {
    this.loading = true;
    this.model.isAlertSentViaEmail = value === "On";
    /// save
    this.model = await AccountService.setUserAlertSettings(this.model);
    this.loading = false;
  }

  async setTrackingAlertFrequency(value: string) {
    this.loading = true;
    this.model.trackingAlertFrequencyId = this.schedules.indexOf(value);
    /// save
    this.model = await AccountService.setUserAlertSettings(this.model);
    this.loading = false;
  }

  async setAlertFrequency(value: string) {
    this.loading = true;
    this.model.alertFrequencyId = this.schedules.indexOf(value);
    /// save
    this.model = await AccountService.setUserAlertSettings(this.model);
    this.loading = false;
  }
  async setSelfBuildCrmAlertFrequency(value: string) {
    this.loading = true;
    this.model.selfBuildCrmAlertFrequencyId = this.schedules.indexOf(value);
    /// save
    this.model = await AccountService.setUserAlertSettings(this.model);
    this.loading = false;
  }
  async setSelfBuildAlertFrequency(value: string) {
    this.loading = true;
    this.model.selfBuildAlertFrequencyId = this.schedules.indexOf(value);
    /// save
    this.model = await AccountService.setUserAlertSettings(this.model);
    this.loading = false;
  }
}
