












import { Component, Vue, Prop } from "vue-property-decorator";

import { AccountService, AuthTokenService, SearchService, WatcherService } from "@/core/services";
import {} from "@/core/components";

@Component({
  components: {},
})
export default class ImpersonationBarCpt extends Vue {
  get userName() {
    return AuthTokenService.currentTokenUser();
  }
  get isImpersonating() {
    return AuthTokenService.isImpersonating();
  }

  async logout() {
    SearchService.clearCache();
    WatcherService.clearCache();
    await AccountService.logout();
    await AccountService.restoreToken();
    window.location.assign("/admin/users/search");
  }

  async created() {}

  async mounted() {}
}
