





































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import { AccountService, ProfileModel } from "@/core/services";
import { BlankSlateCpt } from "@/core/components";

import AppVue from "@/AppVue.vue";

@Component({
  components: {
    BlankSlateCpt: () => Promise.resolve(BlankSlateCpt),
  },
})
export default class CompanyAccountDetailCpt extends AppVue {
  loading: boolean = true;
  actionsVisible = false;
  filter = 0;
  filters = ["All users", "Active users", "Inactive users"];
  maxProfiles = 10;
  get currentFilter() {
    return this.filter;
  }
  get currentProfiles() {
    return this.currentFilter === 1
      ? this.profiles.filter((x) => x.isActive)
      : this.currentFilter === 2
      ? this.profiles.filter((x) => !x.isActive)
      : this.currentFilter === 0
      ? this.profiles
      : [];
  }
  profiles: ProfileModel[] = [];

  get isLoading() {
    return this.loading;
  }

  async created() {}

  async mounted() {
    this.loading = true;
    this.profiles = await AccountService.getCompanyAccounts();
    this.loading = false;
  }
}
