

























































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import {
  ChangePasswordModel,
  AccountService,
  ProfileModel,
  TurnstoneLookupsModel,
  SearchService,
  AccountModel,
} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { LookupCodeModel } from "@/core/models";

@Component({
  components: {},
})
export default class AccountDetailCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  profile = new ProfileModel();
  password = new ChangePasswordModel();
  account = new AccountModel();
  lookups: TurnstoneLookupsModel = new TurnstoneLookupsModel();
  countries: LookupCodeModel[] = [];
  counties: LookupCodeModel[] = [];
  businessTypes: LookupCodeModel[] = [];
  saveAccountLoading = false;
  saveCompanyLoading = false;
  changePasswordLoading = false;
  strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
  get isLoading() {
    return this.loading;
  }

  async created() {
    this.updateTitle("Edit User Profile");
    this.lookups = await SearchService.getLookups();
    this.countries = AccountService.getCountriesDropdown();
    this.counties = AccountService.getCountiesDropdown();
    this.businessTypes = AccountService.getBusinessTypesDropdown();
  }
  get isPasswordValid() {
    if (!this.password.currentPassword) {
      return false;
    }
    if (!this.password.newPassword) {
      return false;
    }
    if (this.password.newPassword !== this.password.confirmPassword) {
      return false;
    }
    return this.strongRegex.test(this.password.newPassword);
  }
  async mounted() {
    await this.refresh();
  }
  async refresh() {
    this.password = new ChangePasswordModel();
    this.profile = await AccountService.getProfile();
    this.account = this.profile as unknown as AccountModel;
  }
  async saveAccount() {
    this.saveAccountLoading = true;
    this.profile = await AccountService.SaveUser(this.profile);
    this.account = this.profile as unknown as AccountModel;
    this.saveAccountLoading = false;
  }
  async saveCompany() {
    this.saveCompanyLoading = true;
    this.profile = await AccountService.SaveCompany(this.account as unknown as ProfileModel);
    this.account = this.profile as unknown as AccountModel;
    this.saveCompanyLoading = false;
  }

  async changePassword() {
    this.changePasswordLoading = true;
    this.password.email = this.profile.email;
    const result = await AccountService.changePassword(this.password);
    this.refresh();
    this.changePasswordLoading = false;
  }
}
