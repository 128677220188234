import { render, staticRenderFns } from "./AccountForgottenProjectsCpt.vue?vue&type=template&id=7cec7848&scoped=true&"
import script from "./AccountForgottenProjectsCpt.vue?vue&type=script&lang=ts&"
export * from "./AccountForgottenProjectsCpt.vue?vue&type=script&lang=ts&"
import style0 from "./AccountForgottenProjectsCpt.vue?vue&type=style&index=0&id=7cec7848&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cec7848",
  null
  
)

export default component.exports