











import { Component, Vue, Prop } from "vue-property-decorator";

import { SubscriptionModel, SubscriptionService } from "@/core/services";
import {} from "@/core/components";
import moment from "moment";

import AppVue from "@/AppVue.vue";
import AccountSubscriptionTableCpt from "./AccountSubscriptionTableCpt.vue";

@Component({
  components: {
    AccountSubscriptionTableCpt: () => Promise.resolve(AccountSubscriptionTableCpt),
  },
})
export default class AccountSubscriptionsCpt extends AppVue {
  loading: boolean = false;
  model: SubscriptionModel[] = [];
  get activeSubscriptions() {
    return this.model.filter((x) => x.statusName === "Active");
  }
  get historySubscriptions() {
    return this.model.filter((x) => x.statusName !== "Active");
  }
  get isLoading() {
    return this.loading;
  }

  async created() {
    this.loading = true;
    this.model = await SubscriptionService.getAll();
    this.loading = false;
  }

  async mounted() {}
}
